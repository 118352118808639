import React, { useEffect } from 'react';
import { Box, Typography, Stack, Checkbox } from '@mui/material';
import { Controller, useController, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import postalCodes from 'postal-codes-js';

import { Payment } from 'src/Types/Currency';
import { DatePickerInput, SelectInput, TextInput } from 'src/components/Inputs';
import { COUNTRIES } from 'src/constants/country';
import { getError, getErrorMessage } from 'src/utils/common';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { usePayment } from 'src/hooks/queries/useCurrency';
import { useSaveCard } from 'src/hooks/queries/useCard';
import { CardInfo } from 'src/Types/Card';
import { useGetProfile } from 'src/hooks/queries/useAuth';
import CardWrapper from 'src/components/Common/CardWrapper';
import TimeOut from './TimeOut';
import SelectCard from './SelectCard';
import { useTranslation } from 'react-i18next';

const formSchema = yup.object().shape({
  card_holder_name: yup.string().required('Required'),
  card_number: yup.string().required('Required'),
  expiration_date: yup
    .string()
    .nullable()
    .required('Required')
    .test('expiration_date', 'Invalid date', (dayofbirth) => {
      const isAfter = dayjs(dayofbirth).isAfter(dayjs());
      const isValid = dayjs(dayofbirth, 'YYYY/MM/DD').isValid();
      return isValid && isAfter;
    }),
  cvv: yup.string().required('Required'),
  country: yup.string().required('Required'),
  state: yup.string().required('Required'),
  address: yup.string().required('Required'),
  city: yup.string().required('Required'),
  zip: yup.string().required('Required'),
});

export const AddNewCard = () => {
  const { i18n, t } = useTranslation();
  const {
    transactionState: { id },
  } = useBuyCurrencyStore();
  const { mutateAsync: payment, isLoading } = usePayment();
  const { mutate: saveCard } = useSaveCard();
  const { data } = useGetProfile();

  const { register, formState, handleSubmit, control, setValue } =
    useForm<Payment>({
      resolver: yupResolver(formSchema),
      mode: 'all',
      defaultValues: { expiration_date: null },
    });

  const { field } = useController({ name: 'expiration_date', control });

  const handleChangeCard = ({
    card_holder_name,
    card_number,
    expiration_date,
  }: CardInfo) => {
    setValue('card_holder_name', card_holder_name);
    setValue('card_number', card_number);
    setValue('expiration_date', expiration_date);
    setValue('cardSaveNumber', card_number);
  };

  const handleSubmitForm = async (values: Payment) => {
    const {
      card_holder_name,
      card_number,
      expiration_date,
      isSaveCard,
      cardSaveNumber,
    } = values;

    const isAcceptSaveCard = isSaveCard && cardSaveNumber !== card_number;
    await payment({
      ...values,
      payment_id: id as string,
      payment_option: 'Card',
    });
    if (isAcceptSaveCard) {
      saveCard({
        card_holder_name,
        card_number,
        expiration_date: expiration_date as string,
      });
    }
  };

  const handleChangeCountry = () => {
    setValue('zip', '');
  };

  useEffect(() => {
    setValue('state', _.get(data, 'state', '') as string);
    setValue('country', _.get(data, 'country.country_short', '') as string);
    setValue('address', _.get(data, 'street1', '') as string);
    setValue('city', _.get(data, 'city', '') as string);
    setValue('zip', _.get(data, 'postal_code', '') as string);
  }, [data]);

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <CardWrapper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4' fontWeight='bold' mb={1}>
            {t('cardPayment')}
          </Typography>
          <TimeOut />
        </Box>
        <Stack spacing={2} mt={4}>
          <SelectCard onChange={handleChangeCard} />
          <TextInput
            label='Cardholder name'
            error={getError(formState, 'card_holder_name')}
            message={getErrorMessage(formState, 'card_holder_name')}
            {...register('card_holder_name')}
          />
          <TextInput
            label='Card number'
            error={getError(formState, 'card_number')}
            message={getErrorMessage(formState, 'card_number')}
            {...register('card_number')}
          />
          <Box display='flex' gap={2}>
            <DatePickerInput
              label='Expiration date'
              error={getError(formState, 'expiration_date')}
              message={getErrorMessage(formState, 'expiration_date')}
              format='MM/YYYY'
              views={['month', 'year']}
              disablePast
              {...field}
            />
            <TextInput
              label='CVV'
              error={getError(formState, 'cvv')}
              message={getErrorMessage(formState, 'cvv')}
              {...register('cvv')}
            />
          </Box>
          <Typography textAlign='center' color='grey.400' fontWeight='bold'>
            {t('billingAddress')}
          </Typography>
          <Box display='flex' gap={2}>
            <Controller
              name='country'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <SelectInput
                  label={t('country')}
                  placeholder={t('selectCountry')}
                  error={getError(formState, 'country')}
                  message={getErrorMessage(formState, 'country')}
                  options={COUNTRIES}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    handleChangeCountry();
                  }}
                />
              )}
            />

            <TextInput
              label={t('zipcode')}
              error={getError(formState, 'zip')}
              message={getErrorMessage(formState, 'zip')}
              {...register('zip')}
            />
          </Box>
          <TextInput
            label={t('address')}
            error={getError(formState, 'address')}
            message={getErrorMessage(formState, 'address')}
            {...register('address')}
          />
          <Box display='flex' gap={2}>
            <TextInput
              label={t('city')}
              error={getError(formState, 'city')}
              message={getErrorMessage(formState, 'city')}
              {...register('city')}
            />
            <TextInput
              label={t('state')}
              error={getError(formState, 'state')}
              message={getErrorMessage(formState, 'state')}
              {...register('state')}
            />
          </Box>
          <Box
            display='flex'
            border='2px solid'
            borderColor='divider'
            borderRadius='12px'
            p={2}
          >
            <Box mr={1}>
              <Checkbox sx={{ padding: 0 }} {...register('isSaveCard')} />
            </Box>
            <Box>
              <Typography
                fontWeight='bold'
                color='grey.700'
                fontSize={{
                  base: 14,
                  xs: 14,
                  sm: 14,
                  md: 16,
                }}
              >
                {t('saveCard')}
              </Typography>
              <Typography
                fontSize={{
                  base: 14,
                  xs: 14,
                  sm: 14,
                  md: 16,
                }}
              >
                {t('cardNotice')}
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Box display='flex' justifyContent='center' mt={2}>
          <LoadingButton
            variant='contained'
            type='submit'
            loading={isLoading}
            fullWidth
          >
            {t('payment')}
          </LoadingButton>
        </Box>
      </CardWrapper>
    </form>
  );
};

export default AddNewCard;

