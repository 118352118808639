import { Avatar, AvatarProps, Box, BoxProps } from '@mui/material';
import React from 'react';
import { MASTER_CARD, VISA, SEPA, INTERAC } from 'src/constants/images';

type Props = {
  containerProps?: BoxProps;
  avatarProps?: AvatarProps;
};

const GlobalPaymentLogos = ({ avatarProps, containerProps }: Props) => (
  <Box display='flex' gap={2} {...containerProps}>
    <Avatar
      src={MASTER_CARD}
      alt='master'
      {...avatarProps}
      sx={{
        borderRadius: 0,
        width: 'auto',
        ...avatarProps?.sx,
      }}
    />
    <Avatar
      src={VISA}
      alt='visa'
      {...avatarProps}
      sx={{
        borderRadius: 0,
        width: 'auto',
        ...avatarProps?.sx,
      }}
    />
    <Avatar
      src={SEPA}
      alt='sepa'
      {...avatarProps}
      sx={{
        borderRadius: 1.5,
        width: 60,
        height: 40,
        ...avatarProps?.sx,
      }}
    />
    <Avatar
      src={INTERAC}
      alt='interac'
      {...avatarProps}
      sx={{
        borderRadius: 1.5,
        width: 60,
        height: 40,
        ...avatarProps?.sx,
      }}
    />
  </Box>
);

export default GlobalPaymentLogos;
