import { Typography } from '@mui/material';
import React from 'react';
import { KycStatus } from 'src/Types/Kyc';

type Props = {
  status: KycStatus
}

export const AccountKycStatus = ({ status }: Props) => {
  const renderStatus = () => {
    if (status === KycStatus.COMPLETED) {
      return (
        <Typography variant="caption" color="success.main" fontWeight="bold">
          Verified
        </Typography>
      );
    }
    if (status === KycStatus.PENDING) {
      return (
        <Typography variant="caption" color="warning.main" fontWeight="bold">
          Pending
        </Typography>
      );
    }
    return (
      <Typography variant="caption" color="warning.main" fontWeight="bold">
        Not verified
      </Typography>
    );
  };

  return renderStatus();
};
