export enum RoutePath {
  HOME = `/`,
  LOGIN = `/login`,
  REGISTER = `/register`,
  TRANSACTION_HISTORY = `/transaction-history`,
  PROFILE = `/profile`,
  PRIVACY = `/privacy`,
  ABOUT_US = `/about-us`,
  FAQ = `/faq`,
  REQUEST = `/request`,
  COOKIE_POLICY = `/cookie-policy`,
  USER_AGREEMENT = `/user-agreement`,
  TRANSACTION_DETAILS = `/transaction-details/:id`,
  REF = `/referral/:id`
}

