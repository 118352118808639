/* eslint-disable no-unused-vars */
export enum KycStatus {
  PENDING = 'pending',
  QUEUED = 'queued',
  ONHOLD = 'onHold',
  COMPLETED = 'completed',
  INIT = 'init',
}

export type GetKycStatusResponse = {
  levelName: string,
  createDate: string,
  reviewStatus: KycStatus,
  priority: number
}

export type GetKycUrlResponse = {
  url: string;
};
