export const ILLUSTRATION_BITMAMBO = '/images/illustration-bitmambo.png';
export const ILLUSTRATION_BITMAMBO_DARK =
  '/images/illustration-bitmambo-dark.png';
export const SEND_EMAIL_ILLUSTRATION = '/images/send-email-illustration.png';
export const OTP = '/images/otp.png';
export const REGISTRATION = '/images/registration.png';
export const LOGO = '/images/logo.png';
export const WALLET = '/images/wallet.png';

export const BITMAMBO_INSTANTLY_DARK = '/images/bitmabo-instanty-dark.svg';
export const BITMAMBO_INSTANTLY_LIGHT = '/images/bitmabo-instanty-light.svg';
export const BITMAMBO_INSTANTLY_LIGHT_FR =
  '/images/bitmabo-instanty-light-fr.svg';
export const BITMAMBO_INSTANTLY_DARK_FR =
  '/images/bitmabo-instanty-dark-fr.svg';

// COIN
export const BTC = '/images/coins/btc.svg';
export const USDC = '/images/coins/usdc.svg';
export const USDT = '/images/coins/usdt.svg';
export const USD = '/images/coins/usd.svg';
export const KES = '/images/coins/kes.svg';
export const VND = '/images/coins/vnd.svg';
export const GNF = '/images/coins/gnf.svg';
export const EUR = '/images/coins/eur.svg';
export const GHS = '/images/coins/ghs.jpeg';
export const XOF = '/images/coins/xof.svg';
export const ETH = '/images/coins/eth.svg';
export const TRON = '/images/coins/trx.svg';
export const POLYGON = '/images/coins/polygon.svg';

//FLAGS
export const FR = '/images/flags/fr.svg';
export const EN = '/images/flags/en.svg';

// LOGO
export const APPLE_PAY = '/images/logo/apple-pay.svg';
export const GPAY = '/images/logo/gpay.svg';
export const MASTER_CARD = '/images/logo/master-card.svg';
export const UPI = '/images/logo/upi.svg';
export const VISA = '/images/logo/visa.svg';
export const SEPA = '/images/logo/sepa.png';
export const INTERAC = '/images/logo/interac.png';
export const MTN = '/images/logo/mtn.png';
export const ORANGE_MONEY = '/images/logo/orange-money.png';
export const MOOV_MONEY = '/images/logo/moov-money.png';
export const CELL_PHONE = '/images/logo/cell-phone.svg';
export const BANK = '/images/logo/bank.svg';

// SOCIAL
export const FACEBOOK = '/images/socials/facebook.svg';
export const INSTAGRAM = '/images/socials/instagram.svg';
export const LINKED_IN = '/images/socials/linked-in.svg';
export const TWITTER = '/images/socials/twitter.svg';
export const YOUTUBE = '/images/socials/youtube.svg';

export const BITMAMBO_BLACK = '/images/logo/bitmambo-black.png';
export const BITMAMBO_WHITE = '/images/logo/bitmambo-white.png';

export const CREDIT_CARD = '/images/credit-card.png';
export const BUY_CRYPTO_AFRICA = '/images/buy-crypto-africa.png';

// MAP CRYPTO ICON
export const MAP_CRYPTO_ICONS = {
  BTC,
  USDC,
  USDT,
};

// MAP CURRENCY ICON
export const MAP_CURRENCY_ICONS = {
  USD,
  VND,
  XOF,
  KES,
  GHS,
  GNF,
  EUR,
};

// MAP LANGUAGE ICON
export const MAP_LANGUAGE_ICON = {
  FR,
  EN,
};

// MAP CURRENCY ICON
export const MAP_NETWORK_ICONS = {
  BTC,
  ETH,
  POLYGON,
  TRON,
};

